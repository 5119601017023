import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Twitter from '../images/twitter.inline.svg'
import Github from '../images/github.inline.svg'
import Discord from '../images/discord.inline.svg'
import Telegram from '../images/telegram.inline.svg'
import Youtube from '../images/youtubeBlack.png'
import YoutubeLighmode from '../images/youtubeLighmode.png'
import { useDarkMode } from '../contexts/Application'

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  position: relative;
  padding: 2rem;

  @media (max-width: 1155px) {
    display: block;
  }

  @media (max-width: 960px) {
    padding: 1rem;
  }
`

const StyledFooterLinkSection = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`

const StyledFooterLink = styled(Link)`
  margin-right: 12px;
  color: ${({ theme }) => theme.textColor};
`

const StyledTwitter = styled(Twitter)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 16px;
  height: 16px;
  margin-right: 12px;
`

const StyledDiscord = styled(Discord)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 16px;
  height: 16px;
  margin-right: 12px;
`

const StyledYoutube = styled(Youtube)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 16px;
  height: 16px;
  margin-right: 12px;
`

const StyledTelegram = styled(Telegram)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  margin-left: -4px;
  margin-right: 2px;
  transform: scale(0.65);
`

const StyledGithub = styled(Github)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 16px;
  height: 16px;
  margin-right: 12px;
`

const Footer = () => {
  const [darkMode, toggleDarkMode] = useDarkMode()
  return (
    <StyledFooter>
      <p style={{ margin: 0 }}>© {new Date().getFullYear()} Pegasys</p>
      <StyledFooterLinkSection>
        <StyledFooterLink to="/about">About</StyledFooterLink>
        <StyledFooterLink to="/disclaimer" style={{ marginRight: '12px' }}>
          Disclaimer
        </StyledFooterLink>
        <StyledFooterLink to="/trademarks">Trademarks</StyledFooterLink>
        <StyledFooterLink to="/about#brand">Brand</StyledFooterLink>
        <StyledFooterLink to="/terms-of-service">Terms</StyledFooterLink>
      </StyledFooterLinkSection>
      <StyledFooterLinkSection>
        <a href="https://twitter.com/PegasysDAO" rel="noopener noreferrer" target="_blank">
          <StyledTwitter />
        </a>
        <a href="https://github.com/Pegasys-fi" rel="noopener noreferrer" target="_blank">
          <StyledGithub />
        </a>
        <a href="https://discord.gg/Pegasys" rel="noopener noreferrer" target="_blank">
          <StyledDiscord />
        </a>
        <a href="https://www.youtube.com/@PegasysDAO" rel="noopener noreferrer" target="_blank">
          {darkMode ? (
            <img
              src={YoutubeLighmode}
              style={{
                width: '20px',
                height: '20px',
                marginTop: '20px',
                marginLeft: '5px'
              }}
            />
          ) : (
            <img
              src={Youtube}
              style={{
                width: '20px',
                height: '20px',
                marginTop: '20px',
                marginLeft: '5px'
              }}
            />
          )}
        </a>
      </StyledFooterLinkSection>
    </StyledFooter>
  )
}
export default Footer
